import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tab'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import Chartkick from 'chartkick'
import Chart from 'chart.js'
Chartkick.use(Chart)

const application = Application.start()
const context = require.context('../controllers', true, /.js$/)
application.load(definitionsFromContext(context))

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('select2')
require('tablesorter')
require('tablesorter/dist/js/widgets/widget-output.min')
