import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['activity']

  load (event) {
    event.preventDefault()

    this.activityTarget.classList.add('loading')
    const loader = document.createElement('div')
    loader.classList.add('loader')
    $(this.activityTarget).html(loader)

    const url = event.target.getAttribute('href')
    $.getScript(url, () => {
      this.activityTarget.classList.remove('loading')
    })
  }
}
