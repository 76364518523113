import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['isAdmin']

  togglePermissions () {
    const isChecked = this.isAdminTarget.checked
    if (isChecked) {
      $('#user-sections').attr('disabled', true)
      $('#user-sections').find('input[type=checkbox]').prop('checked', true)
    } else {
      $('#user-sections').removeAttr('disabled')
    }
  }

  submit () {
    $('form.new_user, form.edit_user').find('input[type=submit]')
      .attr('disabled', true)
      .val('Saving...')
  }
}
