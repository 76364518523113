import { Controller } from 'stimulus'
import Flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['online']

  connect () {
    this.datepicker()

    if (document.getElementById('online-number')) {
      $.get('dashboard/online', () => this.updateCounter())
    }
  }

  updateCounter () {
    if (document.getElementById('online-number')) {
      setTimeout(() => {
        $.get('dashboard/online', () => this.updateCounter())
      }, 30000)
    }
  }

  datepicker () {
    const $form = $('#dashboard-filter-form')
    const $elm = $('#report-period-select')

    if ($form.length && $elm.length) {
      const from = $elm.data('from')
      const to = $elm.data('to')

      $('.btn-group-toggle input:radio').change(() => {
        $form.submit()
      })

      Flatpickr($elm[0], {
        mode: 'range',
        altInput: true,
        altFormat: 'M j, Y',
        altInputClass: 'flatpickr-alt-input',
        dateFormat: 'Y-m-d',
        defaultDate: [from, to],
        onValueUpdate: [function (selectedDates) {
          const dates = selectedDates.map(date => this.formatDate(date, 'Y-m-d'))
          $('#report-from').val(dates[0])
          $('#report-to').val(dates[1])

          if (dates.length === 2) {
            $form.submit()
          }
        }]
      })
    }
  }
}
