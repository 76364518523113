import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['edit', 'form', 'table']

  connect () {
    console.log('custom-maps controller')
    this.formTarget.style.display = 'none'
  }

  edit (event) {
    event.preventDefault()
    this.formTarget.style.display = 'block'
    this.tableTarget.style.display = 'none'
    this.editTarget.style.display = 'none'
  }

  cancel (event) {
    event.preventDefault()
    this.formTarget.style.display = 'none'
    this.tableTarget.style.display = 'block'
    this.editTarget.style.display = 'block'
  }
}
