import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'type', 'map', 'track', 'drlOfficial', 'customPhysics', 'class', 'raceType', 'circuit',
    'leaderboard', 'toggle', 'selectLabel', 'btnDelete', 'btnDeleteAll', 'deleteAll'
  ]

  connect () {
    this.filterType()
    this.toggleRemoveButton()
  }

  filterType (e) {
    if (!this.hasTypeTarget) {
      this.toggleRaceType()
      return
    }

    const type = this.typeTarget.value

    if (type === 'Tryouts') {
      [this.mapTarget, this.trackTarget, this.customPhysicsTarget, this.classTarget, this.circuitTarget].forEach(elm => {
        elm.style.display = 'none'
        elm.setAttribute('disabled', true)
      })
    } else if (type === 'DRL Leaders') {
      [this.customPhysicsTarget, this.classTarget].forEach(elm => {
        elm.style.display = 'none'
        elm.setAttribute('disabled', true)
      });
      [this.mapTarget, this.trackTarget].forEach(elm => {
        elm.style.display = 'block'
        elm.removeAttribute('disabled')
      })
      this.toggleRaceType()
    } else if (type === 'Open Class') {
      [this.mapTarget, this.trackTarget, this.customPhysicsTarget, this.classTarget].forEach(elm => {
        elm.style.display = 'block'
        elm.removeAttribute('disabled')
      })
      this.toggleRaceType()
    }
  }

  onChangeMap (e) {
    $.ajax({
      url: '/tracks',
      data: { map: e.target.value },
      success: (response) => {
        this.trackTarget.innerHTML = response
      }
    })
  }

  onChangeRaceType (e) {
    this.toggleRaceType()
  }

  onClickLeaderboard (e) {
    if (e.target.type === 'checkbox') {
      return
    }
    const $target = $(e.target)
    const checkbox = $target.parents('tr').find('input[type="checkbox"]')
    checkbox.prop('checked', !checkbox.prop('checked'))

    this.toggleRemoveButton()
  }

  onToggleAll () {
    const value = this.toggleTarget.checked
    $('table.table-leaderboard').find('tr.leaderboard').find('input[type="checkbox"]').prop('checked', value)
    if (value) {
      this.selectLabelTarget.textContent = 'Deselect'
    } else {
      this.selectLabelTarget.textContent = 'Select'
    }

    this.toggleRemoveButton()
  }

  toggleRemoveButton () {
    if (!this.hasBtnDeleteTarget) {
      return
    }

    const checkedCount = $('table.table-leaderboard').find('tr.leaderboard').find('input[type="checkbox"]').toArray().filter(elem => elem.checked).length
    if (checkedCount) {
      this.btnDeleteTarget.style.display = 'inline-block'
      this.btnDeleteTarget.textContent = `Delete (${checkedCount})`
    } else {
      this.btnDeleteTarget.style.display = 'none'
    }
  }

  onDeleteAll (e) {
    const confirmed = confirm('Are you sure to delete ALL LEADERBOARDS?')
    if (!confirmed) {
      e.preventDefault()
    }

    this.deleteAllTarget.value = 'true'
  }

  toggleRaceType () {
    const raceType = this.raceTypeTarget.value

    if (raceType === 'CIRCUITS') {
      [this.mapTarget, this.trackTarget].forEach(elm => {
        elm.style.display = 'none'
        elm.setAttribute('disabled', true)
      });
      [this.circuitTarget].forEach(elm => {
        elm.style.display = 'block'
        elm.removeAttribute('disabled')
      })
    } else {
      [this.mapTarget, this.trackTarget].forEach(elm => {
        elm.style.display = 'block'
        elm.removeAttribute('disabled')
      });
      [this.circuitTarget].forEach(elm => {
        elm.style.display = 'none'
        elm.setAttribute('disabled', true)
      })
    }
  }
}
