import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['assetsId']

  connect () {
    const $this = this
    $this.assetsMasterData = []
    $this.loadAssetMasterData().then(function(response) {
      $this.assetsMasterData = response
      const dispatchEventList = ['select2:select','select2:unselecting']
      dispatchEventList.forEach( function(evt) {
        $($this.assetsIdTarget).on(evt, function () {
          const event = new Event('change', { bubbles: true })
          this.dispatchEvent(event)
        })
      })

      if ($this.hasAssetsIdTarget) {
        $this.bindAssetsSelector()
      }
    })
  }

  formatResult (state) {
    let result
    if (state.thumbnail) {
      result = $(`
        <div class="d-flex align-items-center">
          <img src="${state.thumbnail}"  alt="" width=28 class="rounded">
          <span class="pl-2">${state.text}</span>
        </div>
      `)
    }
    return result
  }

  formatSelection (state, masterData) {
    const selectObj = masterData.find((data) => data.id === state.id)
    const thumbnail = selectObj ? selectObj.thumbnail : null
    let selection
    if (thumbnail) {
      selection = $(`
        <div class="d-flex align-items-center">
          <img src="${thumbnail}"  alt="" width=28 class="rounded">
          <span class="pl-2">${state.text}</span>
        </div>
      `)
    } else {
      selection = $(`
        <div class="d-flex">
          <span>${state.text}</span>
        </div>
      `)
    }
    return selection
  }

  bindAssetsSelector () {
    const searchAssetsURL = this.element.dataset.assetsFetchUrl
    const placeholder = this.element.dataset.placeholder
    const $this = this
    $(this.assetsIdTarget).select2({
      allowClear: true,
      placeholder: placeholder,
      ajax: {
        url: searchAssetsURL,
        dataType: 'json'
      },
      templateResult: this.formatResult,
      templateSelection: function (state) {
        return $this.formatSelection(state, $this.assetsMasterData)
      },
      minimumInputLength: 1
    })
  }

  loadAssetMasterData () {
    const $this = this
    return new Promise(function(resolve) {
      const loadMasterDataURL = $this.element.dataset.assetsMasterDataUrl
      $.ajax({
        url: loadMasterDataURL,
        dataType: 'json',
        success: function(response) {
          resolve(response.results)
        }
      })
    })
  }
}
