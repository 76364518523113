import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'playerIds', 'title', 'customDroneContainer',
    'customDroneInput', 'ageCheckNumberContainer',
    'ageCheckNumberInput', 'serverIPContainer',
    'serverIPInput', 'droneClassSelect', 'drlPilotModeInput'
  ]

  connect () {
    this.bindPlayersSelector()
    if ($('#tournament-pilot-mode').length > 0) {
      this.handleChangeDrlPilotMode(this.drlPilotModeInputTarget.checked)
    }
  }

  bindPlayersSelector () {
    const searchPlayersURL = this.data.get('playersFetchUrl')
    if (this.playerIdsTargets.length) {
      this.playerIdsTargets.forEach((elm, index) => {
        $(elm).select2({
          placeholder: 'SteamID or Username',
          ajax: { url: searchPlayersURL, dataType: 'json' }
        })
      })
    }
  }

  onChangeDroneClass (event) {
    const droneClass = event.target.value
    const customDroneContainer = this.customDroneContainerTarget
    const customDroneInput = this.customDroneInputTarget

    if (droneClass === 1) {
      customDroneContainer.style.display = 'block'
      customDroneInput.removeAttribute('disabled')
    } else {
      customDroneContainer.style.display = 'none'
      customDroneInput.setAttribute('disabled', true)
    }
  }

  onChangeAgeAgreement (event) {
    const checked = event.target.checked
    const ageCheckNumberContainer = this.ageCheckNumberContainerTarget
    const ageCheckNumberInput = this.ageCheckNumberInputTarget

    if (checked) {
      ageCheckNumberContainer.style.display = 'block'
      ageCheckNumberInput.removeAttribute('disabled')
    } else {
      ageCheckNumberContainer.style.display = 'none'
      ageCheckNumberInput.setAttribute('disabled', true)
    }
  }

  onChangeDrlPilotMode (event) {
    const checked = event.target.checked
    this.handleChangeDrlPilotMode(checked)
  }

  handleChangeDrlPilotMode (checked) {
    const customDroneInput = this.customDroneInputTarget
    const droneClassSelect = this.droneClassSelectTarget
    const customDroneContainer = this.customDroneContainerTarget
    if (checked) {
      customDroneInput.setAttribute('disabled', true)
      droneClassSelect.setAttribute('disabled', true)
      customDroneInput.value = 'DRD-fc5bf84d13e5bac67957921c'
      droneClassSelect.value = 1
      customDroneContainer.style.display = 'block'
    } else {
      customDroneInput.removeAttribute('disabled')
      droneClassSelect.removeAttribute('disabled')
    }
  }

  onChangeLan (event) {
    const checked = event.target.checked
    const serverIPContainer = this.serverIPContainerTarget
    const serverIPInput = this.serverIPInputTarget

    if (checked) {
      serverIPContainer.style.display = 'block'
      serverIPInput.removeAttribute('disabled')
    } else {
      serverIPContainer.style.display = 'none'
      serverIPInput.setAttribute('disabled', true)
    }
  }

  openSandBoxModal (event) {
    event.preventDefault()
    $('#sandbox-modal').modal('toggle')
  }
}
