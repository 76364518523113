import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'toggler',
    'sidebar',
    'remote',
    'flash',
    'select2'
  ]

  connect () {
    this.remotes()
    this.fadeFlashMessages()
    this.select2()
  }

  remotes () {
    this.remoteTargets.forEach(partial => {
      const url = partial.getAttribute('data-remote-url')
      const id = partial.getAttribute('id')
      const $card = $(`#${id}`)
      const $elm = $card.html($('<div>', { class: 'loader' }))
      const loadingClass = 'loading'
      $elm.addClass(loadingClass)
      $.get(url, (data) => {
        $elm.removeClass(loadingClass).html(data)

        const $download = $card.find('.btn-download')
        if ($download.length > 0) {
          $download.on('click', () => {
            window.location = `${url}&format=csv`
          })
        }
      })
    })
  }

  fadeFlashMessages () {
    this.flashTargets.forEach(flash => {
      $(flash).delay(2500).fadeOut(1000, function () {
        $(this).remove()
      })
    })
  }

  select2 () {
    this.select2Targets.forEach(select => {
      $(select).select2()
    })
  }

  toggleMenu () {
    this.sidebarTarget.classList.toggle('opened')

    setTimeout(() => {
      $('.main-content').one('click', () => {
        if (this.sidebarTarget.classList.contains('opened')) {
          this.sidebarTarget.classList.remove('opened')
        }
      })
    }, 500)
  }
}
