import consumer from './consumer'

consumer.subscriptions.create('BracketsChannel', {
  connected () {
    console.log('connected to BracketsChannel')
  },

  disconnected () {
    console.log('disconnected to BracketsChannel')
  },

  received (data) {
    console.log('receieved data from BracketsChannel', data)

    if (data.refresh) {
      if (data.page === 'bundles') {
        if (
          window.location.href.includes('/bundles') &&
          window.location.href.includes(`branch=${data.branch}`) &&
          window.location.href.includes(`platform=${data.platform}`)
        ) {
          console.log('refreshing bundles page')
          window.Turbolinks.clearCache()
          window.Turbolinks.visit(window.location)
        }
      } else if (data.page === 'asset_management') {
        if (window.location.href.includes('/asset_management')) {
          console.log('refreshing asset management page')
          window.Turbolinks.clearCache()
          window.Turbolinks.visit(window.location)
        }
      } else {
        const container = document.querySelector('[data-tournament-id]')
        const modal = document.getElementById('modal')

        if (
          container &&
          container.dataset.tournamentId === data.tournament_id &&
          modal.style.display !== 'block'
        ) {
          console.log('refreshing tournament page')
          if (!data.only_brackets || !window.location.href.includes('/results/')) {
            window.Turbolinks.clearCache()
            window.Turbolinks.visit(window.location)
          }
        }
      }
    }
  }
})
