import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'toggle',
    'selectLabel',
    'btnReplace',
    'btnReplaceAll',
    'replaceAll',
    'custom_map',
    'currentAssetID',
    'newAssetID',
    'textSearch',
    'updateNewAssetColors',
    'toggleUpdateAsset',
    'allTrackCount'
  ]

  connect () {
    this.toggleRemoveButton()
  }

  onToggleAll () {
    const value = this.toggleTarget.checked
    $('table.table-asset').find('tr.asset').find('input[type="checkbox"]').prop('checked', value)
    if (value) {
      this.selectLabelTarget.textContent = 'Deselect'
    } else {
      this.selectLabelTarget.textContent = 'Select'
    }

    this.toggleRemoveButton()
  }

  toggleRemoveButton () {
    if (!this.hasBtnReplaceTarget) {
      return
    }

    const checkedCount = $('table.table-asset').find('tr.asset').find('input[type="checkbox"]').toArray().filter(elem => elem.checked).length
    if (checkedCount) {
      this.btnReplaceTarget.disabled = false
      this.btnReplaceTarget.style.display = 'inline-block'
      this.btnReplaceTarget.style.cursor = 'pointer'
      this.btnReplaceTarget.textContent = `Replace (${checkedCount})`
    } else {
      this.btnReplaceTarget.style.cursor = 'not-allowed'
      this.btnReplaceTarget.textContent = 'Replace'
      this.btnReplaceTarget.disabled = true
    }
  }

  onReplace (e) {
    const loadingOverlay = document.querySelector('.loading')
    if (this.currentAssetIDTarget.value === '' || this.newAssetIDTarget.value === '') {
      alert('You have not selected current GUID or new GUID !!!')
      e.preventDefault()
    } else {
      loadingOverlay.classList.remove('hidden')
    }
  }

  onReplaceAll (e) {
    const loadingOverlay = document.querySelector('.loading')
    if (this.currentAssetIDTarget.value === '' || this.newAssetIDTarget.value === '') {
      alert('You have not selected current GUID or new GUID !!!')
      e.preventDefault()
    } else {
      const msg = `${this.allTrackCountTarget.value} tracks will be affected by this operation. Are you sure you want to proceed?`
      const confirmed = confirm(msg)
      if (confirmed) {
        this.replaceAllTarget.value = 'true'
        loadingOverlay.classList.remove('hidden')
      } else {
        e.preventDefault()
      }
    }
  }

  onClickMap (e) {
    if (e.target.type === 'checkbox' || e.target.className === 'download-action') {
      return
    }
    const $target = $(e.target)
    const checkbox = $target.parents('tr').find('input[type="checkbox"]')
    checkbox.prop('checked', !checkbox.prop('checked'))

    this.toggleRemoveButton()
  }

  onChangeCurrentGUID (e) {
    this.currentAssetIDTarget.value = e.target.value
  }

  onChangeNewGUID (e) {
    this.newAssetIDTarget.value = e.target.value
  }

  onChangeCheckbox (_e) {
    this.updateNewAssetColorsTarget.value = this.toggleUpdateAssetTarget.checked
  }

  onChangeSearch (e) {
    this.textSearchTarget.value = e.target.value
  }
}
