import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['throttleInput', 'throttleSetup', 'gameMode']

  connect () {
    if (this.throttleInputTarget.checked) {
      this.toggleThrottleSetup()
    }
  }

  toggleThrottleSetup () {
    const display = this.throttleSetupTarget.style.display
    this.throttleSetupTarget.style.display = display == 'flex' ? 'none' : 'flex'

    if (this.gameModeTarget.value == 'leaderboard') {
      this.setFixedThrottleCap(75)
    }
  }

  setFixedThrottleCap (value) {
    const inputs = this.throttleSetupTarget.querySelectorAll('input')
    Array.prototype.forEach.call(inputs, input => {
      input.value = value
      input.readOnly = true
    })
  }

  onSelectGameMode (event) {
    event.preventDefault()
    console.log(this.throttleInputTarget.checked,  this.gameModeTarget.value)
    if (this.throttleInputTarget.checked) {
      if (this.gameModeTarget.value == 'leaderboard') {
        this.setFixedThrottleCap(75)
      } else {
        const inputs = this.throttleSetupTarget.querySelectorAll('input')
        Array.prototype.forEach.call(inputs, input => {
          input.readOnly = false
        })
      }
    }
  }
}
