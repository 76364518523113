import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  filter () {
    this.formTarget.submit()
  }

  readMore (e) {
    e.preventDefault()
    const $this = $(e.target)
    const $container =  $this.closest('.review-content')
    const content = $container.find('.review-full-content').html()
    $container.html(content)
  }
}
