import { Controller } from 'stimulus'
import { Swappable } from '@shopify/draggable'

export default class extends Controller {
  static targets = ['round', 'match']

  initialize () {
    console.log('rounds controller init')
  }

  connect () {
    // const matches = this.matchTargets.filter(item => item.classList.contains('is-draggable'))
    // if (matches.length === 0) {
    //   return false
    // }

    // const swappable = new Swappable(matches, {
    //   draggable: '.match-player',
    //   mirror: {
    //     constrainDimensions: true,
    //   }
    // });

    // let player1, player2 = null
    // let match1, match2 = null
    // let round
    // let canSwap = false

    // swappable.on('swappable:swapped', (event) => {
    //   if (event.dragEvent.sourceContainer === event.dragEvent.overContainer) {
    //     canSwap = false
    //     return false
    //   } else {
    //     round = event.dragEvent.originalSource.closest('.round-container')
    //     player1 = event.dragEvent.originalSource.dataset.steamId
    //     match1 = event.dragEvent.sourceContainer.dataset.matchId

    //     player2 = event.swappedElement.dataset.steamId
    //     match2 = event.dragEvent.overContainer.dataset.matchId
    //     canSwap = true
    //   }
    // })

    // swappable.on('swappable:stop', (event) => {
    //   if (!canSwap) {
    //     return
    //   }

    //   const url = new URL(round.dataset.swapPlayersUrl)
    //   url.searchParams.set('player1', player1)
    //   url.searchParams.set('match1', match1)
    //   url.searchParams.set('player2', player2)
    //   url.searchParams.set('match2', match2)

    //   fetch(url)
    //     .then(data => data.text())
    //     .then(data => console.log(data))
    // })
  }
}
