import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countdown']

  connect () {
    this.countdownTargets.forEach(elm => this.countDown(elm))
  }

  countDown (elm) {
    if (!elm.dataset.end) return

    const now = Date.now()
    const after = Date.parse(elm.dataset.end)
    const diff = after - now

    if (diff <= 0) return

    if (elm.dataset.start) {
      const start = Date.parse(elm.dataset.start)
      if (start > now) {
        this.element.style.display = 'none'
      } else {
        this.element.style.display = 'inline-block'
      }
    }
    elm.innerHTML = this.calculateTimer(diff)

    setTimeout(() => {
      this.countDown(elm)
    }, 1000)
  }

  private
  calculateTimer (diff) {
    const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 365)
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((diff / (1000 * 60)) % 60)
    const seconds = Math.floor((diff / 1000) % 60)

    let timer = ''
    const time = `${hours >= 1 ? hours : '00'}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`
    if (days > 0) {
      timer += `${days} day${days > 1 ? 's' : ''} `
    }

    timer += time

    return timer
  }
}

