import { Controller } from 'stimulus'
import JSONFormatter from 'json-formatter-js'

export default class extends Controller {
  static targets = ['json']

  connect () {
    this.jsonTargets.forEach((item) => {
      const jsonString = item.getAttribute('data-json')
      try {
        let json = JSON.parse(jsonString)
        const formatter = new JSONFormatter(json, 0)
        item.appendChild(formatter.render())
      } catch (error) {
        console.error(error)
      }
    })
  }
}
