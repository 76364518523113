import { Controller } from 'stimulus'

export default class extends Controller {
  removeNotification () {
    const $this = this
    $.ajax({
      url: '/asset_management/notification',
      type: 'DELETE',
      dataType: 'json',
      success: () => {
        $this.element.hidden = true
      }
    })
  }
}
