import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'trackIds'
  ]

  connect () {
    this.bindTracksSelector()
  }

  bindTracksSelector () {
    const searchTracksURL = this.data.get('tracksFetchUrl')
    if (this.trackIdsTargets.length) {
      this.trackIdsTargets.forEach((elm, index) => {
        $(elm).select2({
          placeholder: 'Track ID or Name',
          ajax: { url: searchTracksURL, dataType: 'json' }
        })

        $(elm).on('select2:unselect', function (evt) {
          var element = evt.params.data.element
          var $element = $(element)
          $element.remove()
        })
      })
    }
  }
}
