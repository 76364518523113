import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['playerIds']

  connect () {
    if (this.hasPlayerIdsTarget) {
      this.bindPlayersSelector()
    }
    const urlParams = new URLSearchParams(window.location.search)
    const selectedTab = urlParams.get('tab')
    if (selectedTab) {
      const element = $(`.tab-nav-link:contains("${selectedTab}")`)[0]
      if (element) {
        this.load(element)
      }
    }
  }

  loadTabContent (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.load(e.target)
  }

  load (element) {
    document.querySelectorAll('.tab-nav-link').forEach(link => link.classList.remove('active'))
    element.classList.add('active')
    const url = element.getAttribute('href')
    const $container = document.querySelector('#tab-nav-result')
    $container.classList.add('loader')
    fetch(url)
      .then(data => data.text())
      .then(data => {
        $container.innerHTML = data
      })
      .finally(() => {
        $container.classList.remove('loader')
      })
  }

  formatState (state) {
    const checked = state.element.dataset.readOnly === 'true'

    return $(`
      <span>${state.text}</span>
      <div class="form-check collaborator-read-only">
        <input type="checkbox" class="form-check-input" name="api_custom_map[collaborator][${state.id}]" id="${state._resultId}" ${checked ? 'checked="checked"' : ''} />
        <label class="form-check-label" for="${state._resultId}">Read Only</label>
      </div>
    `)
  }

  bindPlayersSelector () {
    const searchPlayersURL = this.element.dataset.playersFetchUrl

    if (this.playerIdsTargets.length) {
      this.playerIdsTargets.forEach((elm) => {
        $(elm).select2({
          placeholder: 'SteamID or Username',
          ajax: {
            url: searchPlayersURL,
            dataType: 'json'
          },
          templateSelection: this.formatState,
          minimumInputLength: 1
        })
      })
    }
  }
}
