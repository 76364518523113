import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'toggle',
    'selectLabel',
    'btnPublish',
    'btnPublishAll',
    'publishAll',
    'bundle',
    'btnReset',
    'resetBranch',
    'resetPlatform'
  ]

  connect () {
    this.toggleRemoveButton()
  }

  onToggleAll () {
    const value = this.toggleTarget.checked
    $('table.table-bundle').find('tr.bundle').find('input[type="checkbox"]').prop('checked', value)
    if (value) {
      this.selectLabelTarget.textContent = 'Deselect'
    } else {
      this.selectLabelTarget.textContent = 'Select'
    }

    this.toggleRemoveButton()
  }

  toggleRemoveButton () {
    if (!this.hasBtnPublishTarget) {
      return
    }

    const checkedCount = $('table.table-bundle').find('tr.bundle').find('input[type="checkbox"]').toArray().filter(elem => elem.checked).length
    if (checkedCount) {
      this.btnPublishTarget.style.display = 'inline-block'
      this.btnPublishTarget.textContent = `Publish (${checkedCount})`
    } else {
      this.btnPublishTarget.style.display = 'none'
    }
  }

  onPublishAll (e) {
    this.publishAllTarget.value = 'true'
  }

  onReset (e) {
    const loadingOverlay = document.querySelector('.loading');
    const confirmed = confirm('Are you sure you want to reset the bundles files and manifests?')
    if (confirmed) {
      loadingOverlay.classList.remove('hidden');
    } else {
      e.preventDefault()
    }
  }

  onClickBundle (e) {
    if (e.target.type === 'checkbox') {
      return
    }
    const $target = $(e.target)
    const checkbox = $target.parents('tr').find('input[type="checkbox"]')
    checkbox.prop('checked', !checkbox.prop('checked'))

    this.toggleRemoveButton()
  }
}
