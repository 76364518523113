import { Controller } from 'stimulus'
import Flatpickr from 'flatpickr'

export default class extends Controller {
  connect () {
    this.filterForm()
    this.usafTab()
    this.updateUSAF()
    this.tablesorter()
  }

  hightlightUSAF () {
    const $tables = $('.table-usaf')
    if ($tables.length > 0) {
      $tables.each((index, elem) => {
        $(elem).find('tbody tr').each((index, row) => {
          const text = $(row).text().toLowerCase()
          if (text.includes('usaf') || text.includes('air force')) {
            $(row).addClass('highlight')
          }
        })
      })
    }
  }

  tablesorter () {
    $('.table').tablesorter({
      widgets: ['output'],
      widgetOptions: {
        output_delivery: 'download',
        output_dataAttrib: 'data-names'
      }
    })
  }

  download (event) {
    const $btn = $(event.target)
    const target = $btn.data('target')
    const $table = $(target ?? '.table')
    const woOption = $table[0].config.widgetOptions

    woOption.output_saveFileName = $btn.data('name')
    $table.trigger('outputTable')
  }

  updateUSAF () {
    const startDate = $('#reports-from').val()
    const endDate = $('#reports-to').val()
    const type = $('#usaf-type').val()
    const $elem = $('#usaf-report-tab-content')

    if ($elem.length > 0) {
      $elem.html($('<div>', { class: 'loader' }))
      const loadingClass = 'loading'
      $elem.addClass(loadingClass)
      if (type && startDate && endDate) {
        $.get(`/reports/usaf_report?type=${type}&from=${startDate}&to=${endDate}`, (data) => {
          $elem.removeClass(loadingClass).html(data)
          this.hightlightUSAF()
          this.tablesorter()
        })
      }
    }
  }

  filterForm () {
    const $form = $('#report-filter-form')
    const $elm = $('#reports-period')

    if ($form.length) {
      $form.find('select').on('change', () => {
        $form.submit()
      })
    }

    if ($form.length && $elm.length) {
      const from = $elm.data('from')
      const to = $elm.data('to')
      const that = this

      Flatpickr($elm[0], {
        mode: 'range',
        altInput: true,
        altFormat: 'M j, Y',
        altInputClass: 'flatpickr-alt-input',
        dateFormat: 'Y-m-d',
        defaultDate: [from, to],
        onValueUpdate: [function (selectedDates) {
          const dates = selectedDates.map(date => this.formatDate(date, 'Y-m-d'))
          $('#reports-from').val(dates[0])
          $('#reports-to').val(dates[1])
          const $usafElem = $('#usaf-report-tab-content')

          if (dates.length === 2) {
            if ($usafElem.length) {
              that.updateUSAF()
            } else {
              $form.submit()
            }
          }
        }]
      })
    }
  }

  usafTab () {
    const $tab = $('#usaf-report-tabs')
    if ($tab.length > 0) {
      $tab.on('shown.bs.tab', (event) => {
        $('#usaf-type').val($(event.target).attr('href').slice(1))
        $('#download').data('name', `USAF - ${event.target.text}.csv`)
        this.updateUSAF()
      })
    }
  }
}
